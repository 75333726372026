<template>
  <header>
    <div class="header" :class="{ 'fixed-nav': isFixed }">
      <div class="headerInfo">
        <div class="layout clearfix">
          <div class="logo">
            <a href="./">景翔咨询</a>
          </div>
          <ul class="tell">
            <li>
              <span>服务热线</span>
              <p>
                <img
                  class="icon"
                  src="../../../assets/images/tel1.jpg"
                  alt="电话"
                />
                020-39984322
              </p>
            </li>
            <li>
              <span>专家电话</span>
              <p>
                <img
                  class="icon"
                  src="../../../assets/images/tel2.jpg"
                  alt="电话"
                />
                13560068664
              </p>
            </li>
          </ul>
          <form action="/home/index" id="searchFrom" method="get">
            <div class="search-cbox">
              <input name="c" type="hidden" value="book" />
              <input
                class="stext"
                type="text"
                id="keywords"
                name="keywords"
                value
                placeholder="输入搜索关键字"
              />
              <a class="sbtn">搜索</a>
            </div>
          </form>
          <div class="search-btn on" @click="isSearch = true">Search</div>
          <div class="btn-m-list" @click="handleShow">
            <span class="burger burger-1 trans"></span>
            <span class="burger burger-2 trans-fast"></span>
            <span class="burger burger-3 trans"></span>
          </div>
        </div>
      </div>
      <div class="navBox">
        <div class="layout">
          <div class="nav">
            <ul>
              <li
                v-for="(item, index) in menu"
                v-show="!item.meta.hidden"
                :key="index"
                @click="handleMenu(item)"
                :class="{ on: menuName == item.meta.title}"
              >
                <a>
                  <a class="bt">{{ item.meta.title }}</a>
                </a>
                <div
                  class="nav-body"
                  v-show="item.path != '/' && item.path != '/contact'"
                >
                  <a
                    v-for="(v, i) in item.children"
                    :key="i"
                    @click.stop="handleLink(v, item)"
                    v-show="!v.meta.hidden"
                  >
                    <span>{{ v.meta.title }}</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端导航 -->
    <div class="mask" v-show="isShow"></div>
    <div class="mob-nav" :class="{ on: isShow }">
      <div class="neirong-close" @click="isShow = false">close</div>
      <ul class="subtab">
        <li
          v-for="(item, index) in menu"
          v-show="!item.meta.hidden"
          :key="index"
          @click.stop="handleItem(index)"
          :class="{ on: menuindex == index && isFlag }"
        >
          <a
            class="tlink"
            :class="{ subbtn: item.children.length > 1 }"
            @click="handleTo(item)"
          >
            {{ item.meta.title }}
          </a>
          <div
            class="submenu"
            v-if="item.children.length > 1"
            :class="{ active: menuindex == index && isFlag }"
          >
            <ul>
              <li
                v-for="(v, i) in item.children"
                :key="i"
                v-show="!v.meta.hidden"
              >
                <a class="mlink" @click="handleToPage(v)">{{ v.meta.title }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- 移动端搜索 -->
    <form>
      <div
        class="search-Ibox"
        :style="{ display: isSearch ? 'block' : 'none' }"
      >
        <span class="close" @click="isSearch = false">close</span>
        <div class="topsearch-form">
          <div class="topsearch-box">
            <input name="c" type="hidden" value="book" />
            <input
              class="search-text"
              name="keywords"
              id="keywords1"
              type="text"
              value=""
              placeholder="输入搜索关键字"
            />
            <a class="search-btns">&nbsp;</a>
          </div>
        </div>
      </div>
    </form>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuName: "首页",
      isFixed: false,
      menu: this.$router.options.routes,
      isShow: false,
      menuindex: null,
      isFlag: false,
      isSearch: false,
    };
  },
  // 监听当前页面路由
  watch: {
    $route: {
      handler: function (val) {
        if (val.path == "/news/newsDetail") {
          sessionStorage.setItem("menuPath", "/news/company");
        } else if (val.path == "/news/industryDetail") {
          sessionStorage.setItem("menuPath", "/news/industry");
        } else if (val.path == "/study/bookdDetail") {
          sessionStorage.setItem("menuPath", "/study/book");
        } else if (val.path == "/train/trainDetail") {
          sessionStorage.setItem("menuPath", "/train/innerTraining");
        } else {
          sessionStorage.setItem("menuPath", val.path);
        }
        sessionStorage.setItem("menuName", val.matched[0].meta.title);
        this.menuName = val.matched[0].meta.title;
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$nextTick(() => {
      this.menuName = sessionStorage.getItem("menuName")
        ? sessionStorage.getItem("menuName")
        : "首页";
    });
  },
  methods: {
    handleTo(item) {
      if (item.path == "/" || item.path == "/contact") {
        this.isShow = false;
        this.$router.push(item.path);
      }
    },
    handleToPage(v) {
      this.isShow = false;
      this.$router.push(v.path);
    },
    handleShow() {
      this.isShow = true;
      this.isFlag = false;
      this.menuindex = null;
    },
    handleItem(index) {
      if (this.menuindex == index) {
        this.isFlag = !this.isFlag;
      } else {
        this.isFlag = true;
      }
      this.menuindex = index;
    },
    handleMenu(item) {
      this.menuName = item.meta.title;
      sessionStorage.setItem("menuPath", item.redirect);
      sessionStorage.setItem("menuName", item.meta.title);
      this.$router.push(item.path);
    },
    handleLink(v, item) {
      this.menuName = item.meta.title;
      sessionStorage.setItem("menuPath", v.path);
      sessionStorage.setItem("menuName", item.meta.title);
      this.$router.push(v.path);
    },
    // 监听滚动事件
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
header {
  .fixed-nav {
    top: -100px !important;
    -webkit-transition: top 0.5s;
    -moz-transition: top 0.5s;
    -o-transition: top 0.5s;
    transition: top 0.5s;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .header {
    width: 100%;
    height: 150px;
    position: fixed;
    /* position: absolute; */
    left: 0;
    top: 0;
    z-index: 1000;
    .headerInfo {
      width: 100%;
      height: 100px;
      background-color: #fff;
      .logo {
        float: left;
        padding-top: 24px;
        a {
          display: block;
          width: 222px;
          height: 54px;
          font-size: 0;
          background-image: url(../../../assets/images/logo.jpg);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      .tell {
        float: right;
        font-size: 0;
        -webkit-text-size-adjust: none;
        margin-top: 22px;
        li {
          display: inline-block;
          zoom: 1;
          font-size: 14px;
          color: #333;
          margin-left: 60px;
          span {
            display: block;
            margin-left: 25px;
          }
          p {
            font-family: "MontserratRegular";
            font-size: 20px;
            color: #0255c5;
            padding-left: 25px;
            position: relative;
            .icon {
              width: 17px;
              position: absolute;
              left: 0;
              top: 7px;
            }
          }
        }
      }
      .search-cbox {
        width: 256px;
        height: 32px;
        padding-right: 40px;
        margin-top: 33px;
        border: 1px solid #dcdcdc;
        float: right;
        position: relative;
        .stext {
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #000;
          border: none;
          padding: 0 10px;
          background: none;
        }
        .sbtn {
          width: 40px;
          height: 30px;
          font-size: 0;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background-color: #0255c5;
          background-image: url(../../../assets/images/searchBtn.svg);
          background-size: 18px auto;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .search-btn {
        width: 20px;
        height: 20px;
        margin-top: 21px;
        display: none;
        font-size: 0;
        float: right;
        cursor: pointer;
        background-image: url(../../../assets/images/searchBtn_h.svg);
        background-size: 18px auto;
        background-position: center;
        background-repeat: no-repeat;
      }
      .btn-m-list {
        width: 55px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 1020;
        display: none;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.445, 0.145, 0.355, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.445, 0.145, 0.355, 1);
        -moz-transition: all 0.3s cubic-bezier(0.445, 0.145, 0.355, 1);
        .burger {
          width: 17px;
          height: 3px;
          background-color: #000;
          display: block;
          position: absolute;
          z-index: 5;
          top: 16px;
          left: 50%;
          margin: 0 0 0 -10px;
          transform-origin: center;
          -webkit-transform-origin: center;
          -moz-transform-origin: center;
          -o-transform-origin: center;
          -ms-transform-origin: center;
        }
        .trans,
        .trans:hover {
          -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -moz-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -o-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
          -ms-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        .burger-2 {
          top: 25px;
          width: 25px;
        }
        .trans-fast,
        .trans-fast:hover {
          -webkit-transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -o-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
          -ms-transition: all 200ms ease-in-out;
        }
        .burger-3 {
          top: 33px;
          width: 21px;
        }
      }
    }
    .clearfix {
      zoom: 1;
    }
    .navBox {
      width: 100%;
      height: 50px;
      background-color: #0255c5;
      .nav {
        ul {
          li {
            display: inline-block;
            zoom: 1;
            width: 10%;
            position: relative;
            transition: all 0.3s ease;
            .bt {
              display: block;
              font-size: 18px;
              color: #fff;
              height: 50px;
              line-height: 50px;
              text-align: center;
              position: relative;
              z-index: 1;
            }
            .bt:after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #fff;
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: -1;
              transform-origin: 100%;
              -webkit-transform-origin: 100%;
              -webkit-transition: -webkit-transform 0.3s
                cubic-bezier(0.4, 0, 0.2, 1);
              transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
              transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
              transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
                -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
              transform: scaleX(0);
              -webkit-transform: scaleX(0);
              visibility: hidden\9;
            }
            .nav-body {
              opacity: 0;
              visibility: hidden;
              transform: translateY(10px);
              transition: 0.15s ease-out;
            }
            .nav-body {
              width: 100%;
              background-color: #fff;
              border-top: 3px solid #0255c5;
              position: absolute;
              left: 0;
              top: 50px;
              z-index: 5;
              -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07);
              box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07);
              span {
                font-size: 16px;
                color: #666;
                display: block;
                text-align: center;
                padding: 10px 5px;
              }
              span:hover {
                color: #fff;
                background-color: #0255c5;
              }
            }
          }

          li:hover {
            background: #fff;
            .bt {
              color: #0255c5;
            }
            .bt:after {
              transform: scaleX(1);
              transform-origin: 0;
            }
            .nav-body {
              opacity: 1;
              visibility: visible;
              z-index: 10;
              transform: translateY(0);
            }
          }
          .on {
            background: #fff;
            .bt {
              color: #0255c5;
            }
          }
        }
      }
    }
  }
  // 移动端导航
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .mob-nav {
    width: 264px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    visibility: hidden;
    overflow-y: auto;
    background-color: #444;
    z-index: 3000;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-box-shadow: -10px 0 100px 10px rgba(0, 0, 0, 0.7);
    box-shadow: -10px 0 100px 10px rgba(0, 0, 0, 0.7);
    .neirong-close {
      width: 24px;
      height: 24px;
      display: block;
      background-image: url(../../../assets/images/nav-close.svg);
      background-position: center;
      background-size: 24px auto;
      background-repeat: no-repeat;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 0;
      visibility: hidden;
    }
    .subtab {
      padding-top: 60px;
      & > li {
        border-bottom: 1px solid #333;
        position: relative;
        transform: translateX(0rem);
        transition: 0.5s;
      }
      li {
        .tlink {
          background: rgba(33, 33, 33, 0.8);
          -webkit-transform-origin: -100% 0%;
          -moz-transform-origin: -100% 0%;
          -o-transform-origin: -100% 0%;
          -ms-transform-origin: -100% 0%;
          transform-origin: -100% 0%;
          -webkit-transform-style: preserve-3d;
          -moz-transform-style: preserve-3d;
          -o-transform-style: preserve-3d;
          -ms-transform-style: preserve-3d;
          transform-style: preserve-3d;
          -webkit-transform: perspective(800px) rotateX(90deg) rotateY(0deg)
            rotateZ(0deg);
          -moz-transform: perspective(800px) rotateX(90deg) rotateY(0deg)
            rotateZ(0deg);
          -o-transform: perspective(800px) rotateX(90deg) rotateY(0deg)
            rotateZ(0deg);
          -ms-transform: perspective(800px) rotateX(90deg) rotateY(0deg)
            rotateZ(0deg);
          transform: perspective(800px) rotateX(90deg) rotateY(0deg)
            rotateZ(0deg);
          -webkit-transition: all 0.4s linear;
          -moz-transition: all 0.4s linear;
          -o-transition: all 0.4s linear;
          transition: all 0.4s linear;
        }
        .tlink {
          display: block;
          padding: 12px 20px;
          font-size: 16px;
          color: #fff;
          background-color: #8c8c8c;
          position: relative;
        }
        .subbtn:after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
          transform: rotate(45deg);
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -6px;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
        .submenu {
          width: 100%;
          padding: 0 0 15px 0;
          background-color: #444;
          overflow: hidden;
          display: none;
          ul {
            li {
              .mlink {
                // display: block;
                // padding: 10px;
                // font-size: 14px;
                // color: #8c8c8c;
                position: relative;
              }
              a {
                display: block;
                margin-left: 20px;
                padding: 10px;
                font-size: 16px;
                color: #fff;
              }
            }
          }
        }
        .active {
          display: block;
        }
      }
    }
  }

  // 选中
  .mob-nav.on {
    visibility: visible;
    left: 0;
    .neirong-close {
      visibility: visible;
    }
    .subtab {
      & > li {
        transform: translateX(0px);
      }
      li {
        .tlink {
          background: rgba(68, 68, 68, 1);
          -webkit-transform: perspective(800px) rotateX(0deg) rotateY(0deg)
            rotateZ(0deg);
          -moz-transform: perspective(800px) rotateX(0deg) rotateY(0deg)
            rotateZ(0deg);
          -o-transform: perspective(800px) rotateX(0deg) rotateY(0deg)
            rotateZ(0deg);
          -ms-transform: perspective(800px) rotateX(0deg) rotateY(0deg)
            rotateZ(0deg);
          transform: perspective(800px) rotateX(0deg) rotateY(0deg)
            rotateZ(0deg);
        }
      }
      li.on .tlink {
        color: #0255c5;
      }
      li.on .subbtn:after {
        -webkit-transform: rotate(223deg);
        transform: rotate(223deg);
      }
    }
  }

  .mob-nav .subtab > li:nth-child(1) .tlink {
    transition-delay: 150ms;
  }
  .mob-nav .subtab > li:nth-child(2) .tlink {
    transition-delay: 250ms;
  }
  .mob-nav .subtab > li:nth-child(3) .tlink {
    transition-delay: 350ms;
  }
  .mob-nav .subtab > li:nth-child(4) .tlink {
    transition-delay: 450ms;
  }
  .mob-nav .subtab > li:nth-child(5) .tlink {
    transition-delay: 550ms;
  }
  .mob-nav .subtab > li:nth-child(6) .tlink {
    transition-delay: 650ms;
  }
  .mob-nav .subtab > li:nth-child(7) .tlink {
    transition-delay: 750ms;
  }
  .mob-nav .subtab > li:nth-child(8) .tlink {
    transition-delay: 850ms;
  }
  .mob-nav .subtab > li:nth-child(9) .tlink {
    transition-delay: 950ms;
  }

  .search-Ibox {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #222;
    z-index: 1020;
    display: none;
    .close {
      width: 21px;
      height: 21px;
      background: url(../../../assets/images/nav-close.svg) no-repeat scroll
        center center;
      font-size: 0;
      position: absolute;
      right: 3%;
      top: 4%;
      cursor: pointer;
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
    }
    .topsearch-form {
      width: 94%;
      top: 50%;
      position: absolute;
      left: 3%;
      border-bottom: 1px solid #515151;
      height: 120px;
      margin-top: -60px;
      .topsearch-box {
        padding: 0 124px 0 10px;
        position: relative;
        .search-text {
          width: 100%;
          border: 0 none;
          padding: 0;
          margin: 0;
          display: block;
          height: 120px;
          line-height: 120px;
          font-size: 50px;
          color: #fff;
          background-color: transparent;
          text-align: center;
        }
        .search-btns {
          display: block;
          width: 124px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          padding: 0;
          margin: 0;
          border: 0 none;
          background: url(../../../assets/images/searchBtn.svg) no-repeat scroll
            center center #0255c5;
          cursor: pointer;
          transition: background 0.3s;
          -webkit-transition: background 0.3s;
        }
      }
    }
    @media screen and (min-width: 320px) and (max-width: 767px) {
      .topsearch-form {
        height: 40px;
        top: auto;
        left: auto;
        margin: 25% auto 0;
        position: relative;
        .topsearch-box {
          .search-text {
            height: 40px;
            line-height: 40px;
            font-size: 20px;
          }
          .search-btns {
            width: 40px;
            background-size: 24px auto;
          }
        }
      }
    }
    @media screen and (max-width: 880px) {
      .topsearch-form {
        height: 40px;
        top: auto;
        left: auto;
        margin: 25% auto 0;
        position: relative;
        .topsearch-box {
          .search-text {
            height: 40px;
            line-height: 40px;
            font-size: 20px;
          }
          .search-btns {
            width: 40px;
            background-size: 24px auto;
          }
        }
      }
    }
  }
}
</style>
