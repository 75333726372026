<template>
  <div id="wrapper">
    <Header />
    <div class="container">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
    <Category />
    <Footer />
  </div>
</template>

<script>
import { Header, Footer, Category } from "./components";
export default {
  components: {
    Header,
    Footer,
    Category
  },
};
</script>

<style lang="less" scoped>
#wrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .container {
    width: 100%;
    padding-top: 150px;
  }
}
</style>
