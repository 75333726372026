import Quill from "quill";
const EmbedBlot = Quill.import("formats/image");
// 添加style,解决重进编辑器ima标签丢掉style属性问题
const ATTRIBUTES = ["alt", "height", "width", "style"];

class Image extends EmbedBlot {
  static blotName = "image";
  static tagName = "IMG";

  static create(value) {
    const node = super.create(value);
    if (typeof value === "string") {
      // 保留原来的图片方法
      node.setAttribute("src", this.sanitize(value));
    } else {
      // 自定义图片方法
      node.setAttribute("src", value.src);
      // 使用了vue-photo-preview所以添加preview，preview-text这两个属性
      node.setAttribute("preview", "1"); // preview相同的为一组
      node.setAttribute("preview-text", value.title); // 图片名称，预览时显示使用
    }
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static register() {
    if (/Firefox/i.test(navigator.userAgent)) {
      setTimeout(() => {
        // Disable image resizing in Firefox
        // @ts-expect-error
        document.execCommand("enableObjectResizing", false, false);
      }, 1);
    }
  }

  static sanitize(url) {
    return sanitize(url, ["http", "https", "data"]) ? url : "//:0";
  }

  static value(domNode) {
    return domNode.getAttribute("src");
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
function sanitize(url, protocols) {
  const anchor = document.createElement("a");
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(":"));
  return protocols.indexOf(protocol) > -1;
}

export default Image;
