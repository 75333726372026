<template>
  <div class="category-call">
    <ul>
      <li class="online">
        <a class="btn"
          ><span class="icon">图标</span>
          <p>在线客服</p></a
        >
      </li>
      <li class="tel">
        <a class="btn"
          ><span class="icon">图标</span>
          <p>电话咨询</p></a
        >
        <div class="telBox">13560068664（微信同号）</div>
      </li>
      <li class="wechat">
        <a class="btn"
          ><span class="icon">图标</span>
          <p>微信关注</p></a
        >
        <div class="ewmBox">
          <img
            align="扫一扫关注我们"
            src="../../../assets/images/QuickMark.png"
          />
        </div>
      </li>
      <li class="qq">
        <a
          href="http://wpa.qq.com/msgrd?v=3&amp;uin=173976414&amp;site=广州市景翔信息科技有限公司&amp;menu=yes"
          class="btn"
          ><span class="icon">图标</span>
          <p>QQ询咨</p></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.category-call {
  width: 80px;
  background-color: #fff;
  box-shadow: 0 0 20px #dfdfdf;
  position: fixed;
  top: 45%;
  right: 30px;
  z-index: 10;
  ul {
    li {
      border-bottom: 1px solid #f5f5f5;
      position: relative;
      .btn {
        width: 100%;
        height: 90px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .icon {
          display: block;
          width: 40px;
          height: 40px;
          margin: 0 auto;
          font-size: 0;
          background-position: 0 0;
          background-repeat: no-repeat;
          transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
        }
        p {
          font-size: 14px;
          color: #0255c5;
          width: 100%;
          height: 14px;
          line-height: 1;
          margin-top: -15px;
          text-align: center;
        }
      }
      .telBox {
        width: 110px;
        min-height: 100%;
        text-align: center;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0 0 20px #dfdfdf;
        position: absolute;
        right: 80px;
        top: 0;
        -webkit-transition: all 0.5s;
        -webkit-transform: rotateX(-180deg);
        -webkit-transform-origin: center bottom;
        opacity: 0;
        visibility: hidden;
        font-size: 14px;
        padding-top: 25px;
      }
      .ewmBox {
        width: 110px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0 0 20px #dfdfdf;
        position: absolute;
        right: 80px;
        top: 0;
        -webkit-transition: all 0.5s;
        -webkit-transform: rotateX(-180deg);
        -webkit-transform-origin: center bottom;
        opacity: 0;
        visibility: hidden;
        img {
          max-width: 100%;
          display: block;
        }
      }
    }
    li:hover {
      .btn {
        background-color: #0255c5;
      }
      .icon {
        background-position: -40px 0;
      }
      p {
        color: #fff;
      }
      .telBox,
      .ewmBox {
        opacity: 1;
        visibility: visible;
        -webkit-transform: rotateX(0);
      }
    }
    .online {
      .icon {
        background-image: url(../../../assets/images/category_call01.png);
      }
    }
    .tel {
      .icon {
        background-image: url(../../../assets/images/category_call02.png);
      }
    }
    .wechat {
      .icon {
        background-image: url(../../../assets/images/category_call03.png);
      }
    }
    .qq {
      .icon {
        background-image: url(../../../assets/images/category_call04.png);
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1450px) {
  .category-call {
    position: fixed;
    left: 0;
    top: 30%;
    z-index: 100;
    .telBox,.ewmBox{
      left: 80px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .category-call {
    position: fixed;
    left: 0;
    top: 30%;
    z-index: 100;
    display: none;
  }
}
</style>
