import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home/index",
    meta: { title: "首页" },
    children: [
      {
        path: "/home/index",
        component: () => import("@/views/home/index"),
        meta: { title: "广州市景翔信息科技有限公司", keepAlive: true },
      },
    ],
  },
  // 登录去文章编辑
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    meta: { title: "登录", hidden: true },
    children: [],
  },
  // 走进景翔
  {
    path: "/about",
    component: Layout,
    redirect: "/about/profile",
    meta: { title: "走进景翔" },
    children: [
      {
        path: "/about/profile",
        component: () => import("@/views/about/profile.vue"),
        meta: { title: "公司简介" },
      },
      {
        path: "/about/service",
        component: () => import("@/views/about/service.vue"),
        meta: { title: "服务理念" },
      },
      {
        path: "/about/honor",
        component: () => import("@/views/about/honor.vue"),
        meta: { title: "资质荣誉" },
      },
    ],
  },
  // 新闻中心
  {
    path: "/news",
    component: Layout,
    redirect: "/news/company",
    meta: { title: "新闻中心" },
    children: [
      {
        path: "/news/company",
        component: () => import("@/views/news/company.vue"),
        meta: { title: "公司新闻" },
      },
      {
        path: "/news/industry",
        component: () => import("@/views/news/industry.vue"),
        meta: { title: "行业资讯" },
      },
      {
        path: "/news/newsDetail",
        component: () => import("@/views/news/newsDetail.vue"),
        meta: { title: "公司新闻详情", hidden: true },
      },
      {
        path: "/news/industryDetail",
        component: () => import("@/views/news/industryDetail.vue.vue"),
        meta: { title: "行业资讯详情", hidden: true },
      },
      {
        path: "/news/editor",
        component: () => import("@/views/news/editor.vue"),
        meta: { title: "新闻编辑", hidden: true },
      },
    ],
  },
  // 管理咨询
  {
    path: "/consult",
    component: Layout,
    redirect: "/consult/digitization",
    meta: { title: "管理咨询" },
    children: [
      {
        path: "/consult/digitization",
        component: () => import("@/views/consult/digitization.vue"),
        meta: { title: "数字化管理体系" },
      },
      {
        path: "/consult/flow",
        component: () => import("@/views/consult/flow.vue"),
        meta: { title: "流程优化" },
      },
      {
        path: "/consult/performance",
        component: () => import("@/views/consult/performance.vue"),
        meta: { title: "绩效驱动管理" },
      },
      {
        path: "/consult/zeroDefect",
        component: () => import("@/views/consult/zeroDefect.vue"),
        meta: { title: "零缺陷管理" },
      },
      {
        path: "/consult/aswisercom",
        component: () => import("@/views/consult/aswisercom.vue"),
        meta: { title: "精益管理" },
      },
      {
        path: "/consult/construction",
        component: () => import("@/views/consult/construction.vue"),
        meta: { title: "管理建设体系" },
      },
      {
        path: "/consult/standardisation",
        component: () => import("@/views/consult/standardisation.vue"),
        meta: { title: "服务标准化建设" },
      },
      {
        path: "/consult/evaluate",
        component: () => import("@/views/consult/evaluate.vue"),
        meta: { title: "智能制造成熟度评估" },
      },
    ],
  },
  // 管理软件
  {
    path: "/software",
    component: Layout,
    redirect: "/software/fmea",
    meta: { title: "管理软件" },
    children: [
      {
        path: "/software/fmea",
        component: () => import("@/views/software/fmea.vue"),
        meta: { title: "FMEA管理系统" },
      },
      {
        path: "/software/perf",
        component: () => import("@/views/software/perf.vue"),
        meta: { title: "绩效管理系统" },
      },
      {
        path: "/software/regime",
        component: () => import("@/views/software/regime.vue"),
        meta: { title: "制度落地检查系统" },
      },
      {
        path: "/software/project",
        component: () => import("@/views/software/project.vue"),
        meta: { title: "工程管理系统" },
      },
      {
        path: "/software/equipment",
        component: () => import("@/views/software/equipment.vue"),
        meta: { title: "设备管理系统" },
      },
      {
        path: "/software/laundry",
        component: () => import("@/views/software/laundry.vue"),
        meta: { title: "洗衣管理系统" },
      },
    ],
  },
  // 管理培训
  {
    path: "/train",
    component: Layout,
    redirect: "/train/innerTraining",
    meta: { title: "管理培训" },
    children: [
      {
        path: "/train/innerTraining",
        component: () => import("@/views/train/innerTraining.vue"),
        meta: { title: "内训课清单" },
      },
      {
        path: "/train/trainDetail",
        component: () => import("@/views/train/trainDetail.vue"),
        meta: { title: "内训课清单详情", hidden: true },
      },
      {
        path: "/train/digitalSystem",
        component: () => import("@/views/train/digitalSystem.vue"),
        meta: { title: "数字化体系" },
      },
      {
        path: "/train/drive",
        component: () => import("@/views/train/drive.vue"),
        meta: { title: "绩效驱动业绩增长" },
      },
      {
        path: "/train/product",
        component: () => import("@/views/train/product.vue"),
        meta: { title: "产品零缺陷分析" },
      },
      {
        path: "/train/sixS",
        component: () => import("@/views/train/sixS.vue"),
        meta: { title: "6S与目视管理" },
      },
      {
        path: "/train/tool",
        component: () => import("@/views/train/tool.vue"),
        meta: { title: "质量管理五大工具" },
      },
      {
        path: "/train/jobForeman",
        component: () => import("@/views/train/jobForeman.vue"),
        meta: { title: "班组长能力提升" },
      },
    ],
  },
  // 认证咨询
  {
    path: "/authentication",
    component: Layout,
    redirect: "/authentication/IATF16949",
    meta: { title: "认证咨询" },
    children: [
      {
        path: "/authentication/IATF16949",
        component: () => import("@/views/authentication/IATF16949.vue"),
        meta: { title: "IATF16949" },
      },
      {
        path: "/authentication/ISO50001",
        component: () => import("@/views/authentication/ISO50001.vue"),
        meta: { title: "ISO50001" },
      },
      {
        path: "/authentication/ISO27001",
        component: () => import("@/views/authentication/ISO27001.vue"),
        meta: { title: "ISO27001" },
      },
      {
        path: "/authentication/ISO9001",
        component: () => import("@/views/authentication/ISO9001.vue"),
        meta: { title: "ISO9001" },
      },
      {
        path: "/authentication/ISO14001",
        component: () => import("@/views/authentication/ISO14001.vue"),
        meta: { title: "ISO14001" },
      },
      {
        path: "/authentication/ISO45001",
        component: () => import("@/views/authentication/ISO45001.vue"),
        meta: { title: "ISO45001" },
      },
    ],
  },
  // 景翔研究
  {
    path: "/study",
    component: Layout,
    redirect: "/study/book",
    meta: { title: "景翔研究" },
    children: [
      {
        path: "/study/book",
        component: () => import("@/views/study/book.vue"),
        meta: { title: "景翔书籍", keepAlive: true },
      },
      {
        path: "/study/bookdDetail",
        component: () => import("@/views/study/bookdDetail.vue"),
        meta: { title: "书籍详情", hidden: true },
      },
    ],
  },
  // 联系我们
  {
    path: "/contact",
    component: Layout,
    redirect: "/contact/index",
    meta: { title: "联系我们" },
    children: [
      {
        path: "/contact/index",
        component: () => import("@/views/contact/index.vue"),
        meta: { title: "联系我们" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // 切换页面滚动条归0
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// 点击重复路径报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 动态修改浏览器标签页名字
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (to.path === "/home/index") {
    sessionStorage.setItem("menuPath", "/home/index");
    sessionStorage.setItem("menuName", "首页");
  }
  document.title = to.meta.title;

  if (to.path === "/news/editor" && !token) {
    return next("/login");
  } else {
    return next();
  }
});

export default router;
