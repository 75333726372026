import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/styles/reset.css";
import "./assets/styles/common.css";
import "./assets/fonts/fonts.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 富文本组件
import Editor from "@/components/Editor";

Vue.use(ElementUI);
Vue.component("Editor", Editor);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
