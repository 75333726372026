<template>
  <footer>
    <div class="footer">
      <div class="foot-info">
        <div class="layout">
          <div class="foot-text">
            <h2 class="h2">联系我们<span>CONTACT US</span></h2>
            <h3 class="h3">服务热线：<span>020-39984322</span></h3>
          </div>
          <div class="foot-box clearfix">
            <div class="foot-add">
              <p>地址：广州市番禺区洛溪新城105国道97号东基商务大楼6楼</p>
              <p>电话：020-39984322 / 39984311</p>
              <p>传真：020-39984300</p>
            </div>
            <div class="foot-tel">
              <p>手机：13560068664（微信同号）</p>
              <p>E-mail：mail@jeansawn.com</p>
              <p>网址：http://www.jeansawn.com</p>
            </div>
            <div class="foot-sns">
              <div
                class="bdsharebuttonbox bdshare-button-style0-16"
                data-bd-bind="1727142296637"
              >
                <a
                  class="bds_weixin"
                  data-cmd="weixin"
                  title="分享到微信"
                  @click="shareToWeChat"
                ></a>
                <a
                  href="#"
                  class="popup_sqq"
                  data-cmd="sqq"
                  title="分享到QQ好友"
                  @click="shareToQQ"
                ></a>
                <a
                  href="#"
                  class="bds_tsina"
                  data-cmd="tsina"
                  title="分享到新浪微博"
                  @click="shareToMicroblog"
                ></a>
              </div>
            </div>
            <div class="foot-ewm">
              <img
                alt="扫一扫关注我们"
                src="../../../assets/images/QuickMark.png"
              />
              <p>扫一扫关注我们</p>
            </div>
          </div>
        </div>
      </div>
      <div class="foot-copy">
        <div class="layout">
          <p>
            http://www.jeansawn.com 广州市景翔信息科技有限公司
            <a href="https://beian.miit.gov.cn" target="_blank"
              >粤ICP备2021114949号</a
            >
            ©2024
          </p>
        </div>
      </div>
      <div class="bd_weixin_popup" v-show="isShow">
        <div class="bd_weixin_popup_head">
          <span>分享到微信朋友圈</span
          ><a @click="isShow = false" class="bd_weixin_popup_close">×</a>
        </div>
        <canvas id="QRCode_header"></canvas>
        <div class="bd_weixin_popup_foot">
          打开微信，点击底部的“发现”，<br />使用“扫一扫”即可将网页分享至朋友圈。
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import QRCode from "qrcode"; //引入生成二维码插件
export default {
  data() {
    return {
      title: "广州市景翔信息科技有限公司",
      shareUrl: "https://www.jeansawn.com",
      isShow: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getQRCode();
    });
  },
  methods: {
    getQRCode() {
      //生成的二维码为URL地址js
      this.qrUrl = "https://www.jeansawn.com/index.html?uid=1&zid=1&roomid=53";
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 198, //宽
        height: 198, //高
        text: "https://www.jeansawn.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById("QRCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      });
    },
    // 微信
    shareToWeChat() {
      this.isShow = true;
    },
    // qq
    shareToQQ() {
      //qq空间
      // window.open(
      //   "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
      //     encodeURIComponent(this.shareUrl) +
      //     "&title=" +
      //     this.title +
      //     "&summary=" +
      //     ""
      // );

      //qq好友
      window.open(
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(this.shareUrl) +
          "&title=" +
          this.title +
          "&desc=" +
          ""
      );
    },

    // 微博
    shareToMicroblog() {
      //跳转地址
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(this.shareUrl) +
          "&title=" +
          this.title
      );
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background-color: #004385;
  word-break: break-all;
  word-wrap: break-word;
  position: relative;

  .foot-info {
    width: 100%;
    padding: 50px 0;
    background-color: #004385;
    background-image: url(../../../assets/images/footerBg.jpg);
    background-position: 0 bottom;
    background-repeat: no-repeat;

    .foot-text {
      border-bottom: 1px solid #50769c;
      padding-bottom: 10px;
      margin-bottom: 20px;

      .h2 {
        font-size: 18px;
        color: #fff;
        line-height: 1.2;

        span {
          font-family: "MontserratRegular";
          font-size: 14px;
          color: #94a0b4;
          display: block;
        }
      }

      .h3 {
        font-size: 18px;
        color: #94a0b4;
        text-align: right;

        span {
          font-family: "MontserratRegular";
          color: #fff;
        }
      }
    }

    .foot-box {
      .foot-add {
        width: 41.6%;
        float: left;
        padding-right: 20px;

        p {
          font-size: 14px;
          color: #fff;
          margin: 5px 0;
        }
      }

      .foot-tel {
        width: 23.9%;
        float: left;

        p {
          font-size: 14px;
          color: #fff;
          margin: 5px 0;
        }
      }

      .foot-sns {
        float: left;
        font-size: 0;
        margin-top: 30px;

        .bdshare-button-style0-16 {
          zoom: 1;

          .bds_weixin {
            background-image: url(../../../assets/images/sns_wechat.svg);
          }

          .popup_sqq {
            background-image: url(../../../assets/images/sns_qq.svg);
            background-size: 14px auto;
          }

          .bds_tsina {
            background-image: url(../../../assets/images/sns_weibo.svg);
          }

          a {
            width: 32px;
            height: 32px;
            border: 1px solid #fff;
            border-radius: 50%;
            opacity: 0.5;
            display: inline-block;
            float: none;
            margin: 0;
            padding: 0;
            margin-left: 15px;
            background-position: center;
            background-size: 16px auto;
            background-repeat: no-repeat;
          }
        }
      }

      .foot-ewm {
        width: 92px;
        float: right;
        text-align: center;

        img {
          max-width: 100%;
        }

        p {
          font-size: 12px;
          color: #fff;
          margin-top: 2px;
        }
      }
    }
  }

  .foot-copy {
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    color: #666;

    a {
      color: #666;
    }
  }
  .bd_weixin_popup {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    width: 250px;
    background: #fff;
    border: solid 1px #d8d8d8;
    z-index: 11001;
    font-size: 12px;
    .bd_weixin_popup_head {
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      line-height: 16px;
      height: 16px;
      position: relative;
      color: #000;
      .bd_weixin_popup_close {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 0;
        color: #999;
        text-decoration: none;
        font-size: 16px;
      }
    }
    #QRCode_header {
      margin: 15px 10px;
      width: 198px !important;
      height: 198px !important;
    }
    .bd_weixin_popup_foot {
      font-size: 12px;
      text-align: left;
      line-height: 22px;
      color: #666;
    }
  }
}
</style>
